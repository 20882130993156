import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import PhoneInput from 'react-phone-number-input';

import { useTypedTranslation } from '@/shared/hooks/useTranslation';
import { InputModel } from '@/shared/model/form/InputModel';
import { renderTranslationString } from '@/shared/utils/renderTranslation';

import { FormItem } from '../FormItem';

import s from './FormPhoneInput.module.scss';

type PhoneInputProps = {
  className?: string;
  model: InputModel;
  size?: 'small' | 'large';
};

const FormPhoneInput: React.FC<PhoneInputProps> = ({ className, model, size = 'small' }) => {
  const { t } = useTypedTranslation();

  return (
    <FormItem className={cn(s.input, className)} ref={model.ref} model={model}>
      <PhoneInput
        className={cn(s.input__input, s[`input_${size}`], model.isError && s.input_error)}
        {...model.props}
        onChange={model.change}
        placeholder={renderTranslationString(model.placeholder, t)}
      />
    </FormItem>
  );
};

export default observer(FormPhoneInput);
