import { AwbStatus } from '../types';

export const stage = {
  en: {
    optionalFields: {
      messages: {
        saveDataSuccess: 'Data saved successfully',
        saveDataError: 'Failed to save data',
        saveLinkSuccess: 'Link saved successfully',
        saveLinkError: 'Failed to save link',
      },
      specification: {
        label: 'Upload supplier specification',
        tooltip: 'Upload specification also on the next stages, in the “Specification” section',
      },
      arbitraryDocs: {
        label: 'Upload arbitrary documents',
        tooltip: 'Upload documents also on the next stages, in the “Documents” section',
        uploadModalTitle: 'Upload arbitrary documents',
      },
      transitCountryLogisticCompany: {
        label: 'Select logistic company in transit country',
        placeholder: 'Select company',
      },
      destinationCountryLogisticCompany: {
        label: 'Select logistic company in destination country',
        placeholder: 'Select company',
      },
      flightTrackingLink: {
        label: 'Flight tracking link',
        placeholder: 'Attach tracking link also on the next stages, in the “Transportation” section',
      },
      warrantyLoa: {
        label: 'Upload warranty LOA',
      },
      paymentTaxesCertificate: {
        label: 'Upload payment taxes certificate',
        tooltip: 'Upload a certificate of payment of indirect taxes',
      },
      authorizationLetter: {
        label: 'Upload authorization letter',
      },
      photo: {
        label: 'Upload photo',
      },
    },

    requiredStep: {
      awbDoc: {
        descriptionFile: 'Upload AWB for transit country and click “Approve”',
        descriptionNumber: 'Enter flight number and click “Approve”',
        [AwbStatus.wait]: 'When the AWB or flight number is loaded it will appear here',
        [AwbStatus.approveFile]: 'Manager uploaded AWB',
        [AwbStatus.approveNumber]: 'The manager entered the flight number',
        buttonFile: 'AWB',
        buttonNumber: 'Flight number',
        numberPlaceholder: 'Flight number',
      },
    },
    invoiceForm: {
      fileNames: {
        exportInvoice: 'Invoice for export 4%',
        commercialInvoice: 'Commercial invoice',
      },
      date: 'Date',
      country: 'Country of manufacture',
      flightNumber: 'International Air Waybill Number or Flight Number',
      flightNumberPlaceholder: 'Enter number',
      packing: 'Packing',
      incoterms: 'Incoterms',
      signature: 'Authorized signature',
    },
    packing: {
      bag: 'Bag',
      plasticPallet: 'Plastic pallet',
    },
    additionalAgreement: {
      fileName: 'Supplementary agreement',
      date: 'Date',
      signature: 'Authorized signature',
      invoiceNumber: 'Invoice number',
      invoiceNumberPlaceholder: 'Enter number',
      invoiceDate: 'Invoice date',
      debtRepaymentDate: 'Debt repayment date',
    },
    acceptanceCertificate: {
      fileName: 'Transfer and acceptance report',
      date: 'Date',
      signatureJobTitle: 'Job title',
      signature: 'Authorized signature',
      actingUnder: 'Acting under',
      actingUnderPlaceholder: 'Enter document information',
      actingUnderTooltip: 'Enter information about the document under which the signatory is acting',
      customerPersonalId: "Customer's identity document number",
      supplierPersonalId: "Supplier's identity document number",
      personalIdPlaceholder: 'Enter personal id',
      sum: 'Sum',
      sumPlaceholder: 'Enter sum',
    },
    messages: {
      generationSuccess: 'Document generated successfully',
      generationError: 'Document generation error',
      loadFieldsDataError: 'Failed to load document data',
    },
  },
  ru: {
    optionalFields: {
      messages: {
        saveDataSuccess: 'Данные успешно сохранены',
        saveDataError: 'Не удалось сохранить данные',
        saveLinkSuccess: 'Ссылка успешно сохранена',
        saveLinkError: 'Не удалось сохранить ссылку',
      },
      specification: {
        label: 'Загрузите спецификацию поставщика',
        tooltip: 'Загрузить спецификацию также можно на следующих этапах, в разделе “Спецификация”',
      },
      arbitraryDocs: {
        label: 'Загрузите документы по сделке',
        tooltip: 'Загрузить документы также можно на следующих этапах, в разделе “Документы”',
        uploadModalTitle: 'Загрузка документов по сделке',
      },
      transitCountryLogisticCompany: {
        label: 'Выберите логистическую компанию в стране транзита',
        placeholder: 'Выберите компанию',
      },
      destinationCountryLogisticCompany: {
        label: 'Выберите логистическую компанию в стране назначения',
        placeholder: 'Выберите компанию',
      },
      flightTrackingLink: {
        label: 'Ссылка для отслеживания рейса',
        placeholder: 'Укажите ссылку для отслеживания рейса',
      },
      warrantyLoa: {
        label: 'Загрузите доверенность LOA',
      },
      paymentTaxesCertificate: {
        label: 'Загрузите справку об уплате косвенных налогов',
        tooltip: 'Eсли поставщик намерен оформить возврат НДС',
      },
      authorizationLetter: {
        label: 'Загрузите авторизационное письмо',
      },
      photo: {
        label: 'Загрузите фото',
      },
    },
    requiredStep: {
      awbDoc: {
        descriptionFile: 'Загрузите AWB и нажмите “Подтвердить”',
        descriptionNumber: 'Введите номер рейса и нажмите “Подтвердить”',
        [AwbStatus.wait]: 'Когда AWB или номер рейса будет загружен, он появится здесь',
        [AwbStatus.approveFile]: 'Менеджер загрузил AWB',
        [AwbStatus.approveNumber]: 'Менеджер вписал номер рейса',
        buttonFile: 'AWB',
        buttonNumber: 'Номер рейса',
        numberPlaceholder: 'Номер рейса',
      },
    },
    invoiceForm: {
      date: 'Дата',
      country: 'Страна производства',
      flightNumber: 'Номер международной авианакладной или номер рейса',
      flightNumberPlaceholder: 'Введите номер',
      packing: 'Упаковка',
      incoterms: 'Инкотермс',
      signature: 'Cписок подписантов',
    },
    packing: {
      bag: 'Сумка',
      plasticPallet: 'Пластиковый поддон',
    },
    additionalAgreement: {
      date: 'Дата',
      signature: 'Cписок подписантов',
      invoiceNumber: 'Номер инвойса',
      invoiceNumberPlaceholder: 'Введите номер',
      invoiceDate: 'Дата инвойса',
      debtRepaymentDate: 'Дата погашения задолженности',
    },
    acceptanceCertificate: {
      date: 'Дата',
      signatureJobTitle: 'Cписок должностей',
      signature: 'Cписок подписантов',
      actingUnder: 'Действует на основании',
      actingUnderPlaceholder: 'Введите информацию о документе',
      actingUnderTooltip: 'Введите информацию о документе, по которому действует подписант',
      customerPersonalId: 'Номер документа, удостоверяющего личность покупателя',
      supplierPersonalId: 'Номер документа, удостоверяющего личность поставщика',
      personalIdPlaceholder: 'Введите идентификационный номер',
      sum: 'Сумма',
      sumPlaceholder: 'Укажите сумму',
    },
    messages: {
      generationSuccess: 'Документ успешно сгенерирован',
      generationError: 'Ошибка генерации документа',
      loadFieldsDataError: 'Не удалось загрузить данные документа',
    },
  },
};
