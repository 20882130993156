import { BaseResponse } from '@kts-front/types';
import { Centrifuge } from 'centrifuge';
import { action, makeObservable, when } from 'mobx';

import { apiStore, apiUrls } from '@/shared/api';
import { WS_DOMAIN } from '@/shared/config/app';
import { LoadingStageModel, LocalStore, ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { ConnectionResponse, ICentrifugeStore } from './types';

const WS_URL = `wss://${WS_DOMAIN}/connection/websocket`;

export class CentrifugeStore extends LocalStore implements ICentrifugeStore {
  private readonly _client = new ValueModel<Nullable<Centrifuge>>(null);

  private readonly _connectionRequest = apiStore.createRequest<ConnectionResponse>({
    url: apiUrls.chats.connection,
  });

  private readonly _gettingClientStage = new LoadingStageModel();

  constructor() {
    super();

    makeObservable(this, {
      getClient: action.bound,
    });
  }

  async getClient(): Promise<BaseResponse<Centrifuge>> {
    await when(() => !this._gettingClientStage.isLoading);

    if (this._client.value) {
      return {
        isError: false,
        data: this._client.value,
      };
    }

    this._gettingClientStage.loading();

    const token = await this._getToken();

    if (!token) {
      this._gettingClientStage.error();

      return { isError: true };
    }

    const client = new Centrifuge(WS_URL, {
      token,
      getToken: this._getToken,
    });

    this._client.change(client);

    client.connect();

    this._gettingClientStage.success();

    return {
      isError: false,
      data: client,
    };
  }

  private readonly _getToken = async (): Promise<string> => {
    const response = await this._connectionRequest.call();

    if (response.isError) {
      return '';
    }

    return response.data.token;
  };
}
