export const menu = {
  en: {
    clients: 'Clients',
    agents: 'Agents',
    trades: 'Trades',
    documents: 'Documents',
    profile: 'Profile',
    notifications: 'Notifications',
    logout: 'Logout',
    chats: 'Chats',
  },
  ru: {
    clients: 'Клиенты',
    agents: 'Агенты',
    trades: 'Сделки',
    documents: 'Документы',
    profile: 'Профиль',
    notifications: 'Уведомления',
    logout: 'Выход',
    chats: 'Чаты',
  },
};
