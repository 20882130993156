import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { UserType } from '../user';

import { Incoterms, Packing, StageType } from './types';

const T_OPTONS = { ns: 'trades' } as const;

export const mapStageTypeToLabel: Record<StageType, TranslationString> = {
  [StageType.creation]: (t) => t('stageStatus.creation', T_OPTONS),
  [StageType.confirmation]: (t) => t('stageStatus.confirmation', T_OPTONS),
  [StageType.payment]: (t) => t('stageStatus.payment', T_OPTONS),
  [StageType.shipment]: (t) => t('stageStatus.shipment', T_OPTONS),
  [StageType.transportation]: (t) => t('stageStatus.transportation', T_OPTONS),
  [StageType.finalization]: (t) => t('stageStatus.finalization', T_OPTONS),
  [StageType.finished]: (t) => t('stageStatus.finished', T_OPTONS),
};

export const mapUserTypeToStageTypes: Record<UserType, StageType[]> = {
  [UserType.admin]: [
    StageType.creation,
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.transportation,
    StageType.finalization,
  ],
  [UserType.manager]: [
    StageType.creation,
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.transportation,
    StageType.finalization,
  ],
  [UserType.supplier]: [
    StageType.creation,
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.finalization,
  ],
  [UserType.agent]: [
    StageType.creation,
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.transportation,
    StageType.finalization,
  ],
  [UserType.customer]: [StageType.shipment, StageType.transportation, StageType.finalization],
};

export const mapUserTypeToStageTypesInFilters: Record<UserType, StageType[]> = {
  [UserType.admin]: [...mapUserTypeToStageTypes[UserType.admin], StageType.finished],
  [UserType.manager]: [...mapUserTypeToStageTypes[UserType.manager], StageType.finished],
  [UserType.supplier]: [...mapUserTypeToStageTypes[UserType.supplier], StageType.finished],
  [UserType.agent]: [...mapUserTypeToStageTypes[UserType.agent], StageType.finished],
  [UserType.customer]: [...mapUserTypeToStageTypes[UserType.customer], StageType.finished],
};

export const mapUserTypeToStageTypesInChatFilters: Record<UserType, StageType[]> = {
  [UserType.admin]: [
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.transportation,
    StageType.finalization,
    StageType.finished,
  ],
  [UserType.manager]: [
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.transportation,
    StageType.finalization,
    StageType.finished,
  ],
  [UserType.supplier]: [
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.finalization,
    StageType.finished,
  ],
  [UserType.agent]: [
    StageType.confirmation,
    StageType.payment,
    StageType.shipment,
    StageType.transportation,
    StageType.finalization,
    StageType.finished,
  ],
  [UserType.customer]: [...mapUserTypeToStageTypes[UserType.customer], StageType.finished],
};

export const packingOptions: Options<Packing> = [
  {
    value: Packing.bag,
    label: (t) => t('packing.bag', { ns: 'stage' }),
  },
  {
    value: Packing.plasticPallet,
    label: (t) => t('packing.plasticPallet', { ns: 'stage' }),
  },
];

export const incotermOptions: Options<Incoterms> = [
  Incoterms.EXW,
  Incoterms.FCA,
  Incoterms.CPT,
  Incoterms.CIP,
  Incoterms.DAT,
  Incoterms.DAP,
  Incoterms.DDP,
  Incoterms.FAS,
  Incoterms.FOB,
  Incoterms.CFR,
  Incoterms.CIF,
].map((incoterm) => ({
  value: incoterm,
  label: incoterm.toUpperCase(),
}));
