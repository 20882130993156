import { UserType } from '@/entities/user';
import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { mapStageTypeToLabel, StageType, mapUserTypeToStageTypesInChatFilters } from '../stage';

import { TradeInfoKeys } from './types';

export const tradeStageChatFilterOptionsCreator = (userType: UserType): Options<StageType> =>
  mapUserTypeToStageTypesInChatFilters[userType].map((stage) => ({
    value: stage,
    label: mapStageTypeToLabel[stage],
  }));

export const infoKeyList: TradeInfoKeys[] = [
  'supplier',
  'customer',
  'legalCompanyFrom',
  'legalCompanyTo',
  'productType',
  'product',
  'weightWithUnit',
  'ligatureWeightWithUnit',
  'fixingDate',
  'buyAmount',
  'sellAmount',
  'buyAmountTotal',
  'sellAmountTotal',
  'transitCountry',
  'transitLogisticCompany',
  'destinationLogisticCompany',
];

export const mapTradeInfoToLabel = (isMainRole: boolean): Record<TradeInfoKeys, TranslationString> => ({
  supplier: (t) => t('fields.supplier', { ns: 'createTrade' }),
  customer: (t) => t('fields.customer', { ns: 'createTrade' }),
  legalCompanyFrom: (t) => t('fields.legalCompanyFrom', { ns: 'createTrade' }),
  legalCompanyTo: (t) => t(isMainRole ? 'fields.legalCompanyTo' : 'fields.supplier', { ns: 'createTrade' }),
  productType: (t) => t('fields.productType', { ns: 'createTrade' }),
  product: (t) => t('fields.product', { ns: 'createTrade' }),
  weightWithUnit: (t) => t('fields.chemicalWeight', { ns: 'createTrade' }),
  ligatureWeightWithUnit: (t) => t('fields.ligatureWeight', { ns: 'createTrade' }),
  fixingDate: (t) => t('fields.fixingDate', { ns: 'createTrade' }),
  buyAmount: (t) => t('fields.buyPrice', { ns: 'createTrade' }),
  buyAmountTotal: (t) => t('fields.buyPriceTotal', { ns: 'createTrade' }),
  sellAmount: (t) => t(isMainRole ? 'fields.sellPrice' : 'fields.unitPrice', { ns: 'createTrade' }),
  sellAmountTotal: (t) => t(isMainRole ? 'fields.sellPriceTotal' : 'fields.totalСost', { ns: 'createTrade' }),
  transitCountry: (t) =>
    t(isMainRole ? 'tabs.info.fields.transitCountryForManager' : 'tabs.info.fields.transitCountryForOther', {
      ns: 'trade',
    }),
  transitLogisticCompany: (t) => t('tabs.info.fields.transitLogisticCompany', { ns: 'trade' }),
  destinationLogisticCompany: (t) => t('tabs.info.fields.destinationLogisticCompany', { ns: 'trade' }),
});
