export const chats = {
  en: {
    header: {
      title: 'Chats',
      clients: {
        all: 'All',
        supplier: 'Suppliers',
        customer: 'Customers',
      },
      mainSearchInput: 'Find a client/trade',
      searchInput: 'Find a manager/trade',
    },
    plugs: {
      selectChat: 'Select a chat to join the discussion',
      emptyChat: 'There are no messages in this chat yet.',
      emptyChatList: 'Сhat list is empty for now.',
      emptyFilteredChatList: 'No chats were found based on filtering or search results, please change your query',
      emptyMessageList: 'Message list is empty for now.',
    },
    chatPublic: 'General questions',
    threadPublic: 'General stream',
    ownMessagePrefix: 'You: ',
    sentTo: 'Sent to',
    generalStreamModal: {
      title: 'Choose where to send your message',
      alertText:
        'A message sent to the deal chat is automatically duplicated in the general stream. To send a message only to the general stream, check the box below',
      checkboxLabel: 'Only in the general stream',
      labelListDeal: 'Select a chat from the list',
      buttonSubmit: 'Submit',
    },
    exceedsTotalFilesSizeModal: {
      title: 'Files size exceeds 100 MB',
      alertText:
        'The message cannot be sent because the total size of the files exceeds 100 MB. Replace the files and send the message again.',
      buttonSubmit: 'Return',
    },
    messageMenu: {
      edit: 'Edit',
      delete: 'Delete',
    },
    messageInput: {
      placeholder: 'Write your message',
      sendButton: 'Send',
      uploadTooltip: 'Upload files up to 100 MB in total',
      sendTooltip: 'Press Enter to send, Shift+Enter to go to a new line',
    },
    chatsButtons: {
      manager: 'Go to trade chat',
      supplier: 'Chat with supplier',
      customer: 'Chat with customer',
    },
    messages: {
      loadChatListError: 'Failed to load chat list',
      loadMessageListError: 'Failed to load message list',
      sendMessageError: 'There was an error sending your message',
      sendMessageSuccess: 'Message sent successfully',
      deleteMessageSuccess: 'Message deleted successfully',
      deleteMessageError: 'Failed to delete message',
    },
  },
  ru: {
    header: {
      title: 'Чаты',
      clients: {
        all: 'Все',
        supplier: 'Поставщики',
        customer: 'Покупатели',
      },
      mainSearchInput: 'Найти клиента/сделку',
      searchInput: 'Найти менеджера/сделку',
    },
    plugs: {
      selectChat: 'Выберите чат, чтобы присоедениться к обсуждению',
      emptyChat: 'В этом чате пока нет сообщений',
      emptyChatList: 'Список чатов пока пуст',
      emptyFilteredChatList: 'По результатам фильтрации или поиска не было найдено чатов, поменяйте запрос',
      emptyMessageList: 'Список сообщений пока пуст',
    },
    chatPublic: 'Общие вопросы',
    threadPublic: 'Общий Стрим',
    ownMessagePrefix: 'Вы: ',
    sentTo: 'Отправлено в',
    generalStreamModal: {
      title: 'Выберите, куда отправить ваше сообщение',
      alertText:
        'Сообщение, отправленное в чат сделки, автоматические дублируется в общем стриме. Чтобы отправить сообщение только в общий стрим, отметьте галочку ниже',
      checkboxLabel: 'Только в общий стрим',
      labelListDeal: 'Выберите чат из списка',
      buttonSubmit: 'Отправить',
    },
    exceedsTotalFilesSizeModal: {
      title: 'Размер файлов превышает 100 MB',
      alertText:
        'Нельзя отправить сообщение, так как общий размер файлов превышает 100 MB. Замените файлы и отправьте сообщение снова',
      buttonSubmit: 'Вернуться',
    },
    messageMenu: {
      edit: 'Редактировать',
      delete: 'Удалить',
    },
    messageInput: {
      placeholder: 'Напишите ваше сообщение',
      sendButton: 'Отправить',
      uploadTooltip: 'Загрузите файлы общим объемом до 100 MB',
      sendTooltip: 'Нажмите Enter чтобы отправить, Shift+Enter чтобы перейти на новую строку',
    },
    chatsButtons: {
      manager: 'Перейти в чат по сделке',
      supplier: 'Чат с поставщиком',
      customer: 'Чат с покупателем',
    },
    messages: {
      loadChatListError: 'Не удалось загрузить список чатов',
      loadMessageListError: 'Не удалось загрузить список сообщений',
      sendMessageError: 'Произошла ошибка при отправке сообщения',
      sendMessageSuccess: 'Сообщение успешно отправлено',
      deleteMessageSuccess: 'Сообщение успешно удалено',
      deleteMessageError: 'Не удалось удалить сообщение',
      editMessageSuccess: 'Сообщение успешно отредактировано',
      editMessageError: 'Не удалось отредактировать сообщение',
    },
  },
};
