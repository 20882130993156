import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { ChatsQueryParams, IBaseChat, IBaseChatListModel } from '@/entities/chat';
import { ListOffsetModel, LoadingStageModel, LocalStore, QueryParamsModel, ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

export type BaseChatListModelParams = {
  queryParams: QueryParamsModel<ChatsQueryParams>;
  rootStore: IRootStore;
};

type ModelParams = BaseChatListModelParams & {
  searchValue: string;
};

export abstract class BaseChatListModel<ItemModel extends IBaseChat, Payload>
  extends LocalStore
  implements IBaseChatListModel<ItemModel>
{
  readonly list = new ListOffsetModel<ItemModel, number>({ limit: 40 });

  readonly initLoadingStage = new LoadingStageModel();

  protected readonly _searchModel: ValueModel<string>;

  protected readonly _queryParams: QueryParamsModel<ChatsQueryParams>;

  protected readonly _rootStore: IRootStore;
  protected readonly _selectedItemModel: ValueModel<ItemModel | null> = new ValueModel<ItemModel | null>(null);

  constructor({ rootStore, queryParams, searchValue }: ModelParams) {
    super();

    this._rootStore = rootStore;
    this._queryParams = queryParams;

    this._searchModel = new ValueModel(searchValue);

    makeObservable(this, {
      isEmptyList: computed,
      isEmptyFilteredList: computed,
      selectedItem: computed,
      searchValue: computed,
      initiallyLoaded: computed,

      selectItem: action.bound,
      unselectItem: action.bound,
      onChangeSearch: action.bound,
      onSearch: action.bound,
      applyParams: action.bound,
      resetParams: action.bound,
      initialLoad: action.bound,
      loadMore: action.bound,
    });
  }

  get initiallyLoaded(): boolean {
    return !this.list.initial;
  }

  get isEmptyList(): boolean {
    return (
      this.list.items.length === 0 &&
      !this.initLoadingStage.isLoading &&
      !this.list.loadingStage.isLoading &&
      !this._paramsJson
    );
  }

  get isEmptyFilteredList(): boolean {
    return (
      this.list.items.length === 0 &&
      !this.initLoadingStage.isLoading &&
      !this.list.loadingStage.isLoading &&
      Boolean(this._paramsJson)
    );
  }

  get selectedItem(): Nullable<ItemModel> {
    return this._selectedItemModel.value;
  }

  get searchValue(): string {
    return this._searchModel.value;
  }

  async selectItem(itemId: number): Promise<BaseResponse> {
    const itemFromList = this.list.getEntity(itemId);

    if (itemFromList) {
      this._selectedItemModel.change(itemFromList);

      return {
        isError: false,
      };
    }

    /*
     * Если сущности нет в списке, загружаем.
     * Кейс: из треда с поставщиком хотим перейти на тред с покупателем,
     * если по какой-то причине чата/треда с этим покупателем нет (возможно при фильтрации, пагинации), то ничего не отобразится
     * */
    const response = await this._fetchItem(itemId, true);

    if (response.isError) {
      return {
        isError: true,
      };
    }

    this._selectedItemModel.change(response.data as ItemModel);

    return {
      isError: false,
    };
  }

  unselectItem(): void {
    this._selectedItemModel.change(null);
  }

  onChangeSearch(value: string): void {
    this._searchModel.change(value);

    if (value === '') {
      this.applyParams();
    }
  }

  onSearch(value?: string) {
    if (value !== undefined) {
      this._searchModel.change(value);
    }

    this.applyParams();
  }

  async initialLoad({ reset }: { reset: boolean } = { reset: false }): Promise<BaseResponse> {
    if (this.initiallyLoaded && !reset) {
      return {
        isError: false,
      };
    }

    if (this.initLoadingStage.isLoading) {
      return { isError: true };
    }

    this.initLoadingStage.loading();
    this.list.changeOffset(0);

    const response = await this._fetchItems(true);

    if (response.isError) {
      this.initLoadingStage.error();
    } else {
      this.initLoadingStage.success();
    }

    return response;
  }

  async loadMore(): Promise<BaseResponse> {
    if (this.list.loadingStage.isLoading) {
      return { isError: true };
    }

    this.list.loadingStage.loading();
    this.list.changeOffset(this.list.offset + this.list.limit);

    const response = await this._fetchItems(false);

    if (response.isError) {
      this.list.loadingStage.error();
    } else {
      this.list.loadingStage.success();
    }

    return response;
  }

  protected readonly _moveItemToTop = async (
    id: number,
  ): Promise<BaseResponse<{ isNewInList: boolean; item: ItemModel }>> => {
    let item = this.list.getEntity(id);
    let isNewInList: boolean;

    if (!item) {
      const response = await this._fetchItem(id);

      if (response.isError) {
        return { isError: true };
      } else {
        item = response.data as ItemModel;
        this.list.changeOffset(this.list.offset + 1);
        isNewInList = true;
      }
    } else {
      this.list.removeEntity(id);
      isNewInList = false;
    }

    this.list.addEntity({ entity: item, key: id, start: true });

    return {
      isError: false,
      data: { isNewInList, item },
    };
  };

  protected readonly _moveItemToPosition = (newItem: ItemModel): BaseResponse<ItemModel> => {
    const id = newItem.id;
    const prevItem = this.list.getEntity(id);

    if (!prevItem) {
      return { isError: true };
    }

    this.list.removeEntity(id);
    this.list.changeOffset(this.list.offset - 1);

    const lastMessageDate = newItem.lastMessageDate && newItem.lastMessageDate;
    const createdDate = newItem.createdDate;

    let position = -1;

    if (!lastMessageDate) {
      position = this.list.items.findIndex((item) => !item.lastMessageDate && item.createdDate < createdDate);
    } else if (this.list.items.some((item) => item.lastMessageDate)) {
      position = this.list.items.findIndex((item) => item.lastMessageDate && item.lastMessageDate < lastMessageDate);
    } else {
      position = 0;
    }

    if (position < 0) {
      return { isError: true };
    }

    this.list.addEntityByIndex({ entity: newItem, key: id, index: position });
    this.list.changeOffset(this.list.offset + 1);

    return {
      isError: false,
      data: newItem,
    } as BaseResponse<ItemModel>;
  };

  protected abstract get _paramsJson(): Nullable<Payload>;

  abstract applyParams(): void;

  abstract resetParams(): void;

  protected abstract _fetchItem(itemId: number, resetParams?: boolean): Promise<BaseResponse<ItemModel>>;

  protected abstract _fetchItems(reset: boolean): Promise<BaseResponse>;

  destroy(): void {
    super.destroy();
    this.list.entities.forEach((entity) => entity.destroy());
  }
}
