export const documents = {
  en: {
    title: 'Documents',
    colums: {
      name: "Document's name",
      deal: 'Deal',
      access: 'Access',
      date: 'Date',
      original: 'Original',
      product: 'Metal',
      weight: 'Weight',
      supplier: 'Supplier',
      customer: 'Customer',
    },
    confirmation: {
      received: 'Received',
      notReceived: 'Not received',
    },
    documentMarkModal: {
      title: 'Confirm receipt of the original',
      confirm: 'Confirm',
      message: 'Make sure you have the original document in your hands',
      mark: 'Mark receipt',
    },
  },
  ru: {
    title: 'Документы',
    colums: {
      name: 'Название документа',
      deal: 'Сделка',
      access: 'Доступ',
      date: 'Дата',
      original: 'Оригинал',
      product: 'Металл',
      weight: 'Вес',
      supplier: 'Поставщик',
      customer: 'Покупатель',
    },
    confirmation: {
      received: 'Получен',
      notReceived: 'Не получен',
    },
    documentMarkModal: {
      title: 'Подтвердите получение оригинала',
      confirm: 'Подтвердить',
      message: 'Убедитесь, что оригинал документа у вас на руках',
      mark: 'Отметить получение',
    },
  },
};
