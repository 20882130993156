import { DocumentFileType } from '@/entities/file';
import { ShipmentType } from '@/entities/stage';

export const shipmentStage = {
  en: {
    manager: {
      uploadSpec: {
        title: 'Upload supplier specification',
        description: 'Upload specification and click “Approve“',
        modal: {
          title: 'Confirm deletion of the file',
          okText: 'Delete',
        },
      },
      specVerifying: {
        title: 'Verify specification',
        description:
          'Download the specification document from the previous step. Check that all information is correct and click the “All correct” button to proceed further.',
      },
      shipmentType: {
        title: 'Select type of transportation',
        [ShipmentType.direct]: 'Direct',
        [ShipmentType.transit]: 'Transit',
      },
      transitCountry: {
        title: 'Select transit country',
        placeholder: 'Select country',
      },
      transitCountryAwb: {
        title: 'Upload the AWB of the transit country or enter the flight number',
      },
      transitCountryAwbVerifying: {
        title: 'Verify AWB for transit country',
        description:
          'Download the transit country AWB document from the previous step. Check that all information is correct and click the “All correct” button to proceed further.',
      },
      destinationCountryDocs: {
        title: 'Upload documents for destination country',
      },
      destinationCountryAwb: {
        title: "Upload the destination country's AWB or enter the flight number",
        description: 'Upload AWB for destination country and click “Approve”',
      },
      cargoShipment: {
        title: 'Shipping cargo',
        description: 'When the shipment is shipped, confirm shipmen',
      },
    },
    supplier: {
      uploadSpec: {
        title: 'Upload supplier specification',
        description: 'Upload specification and click “Approve”',
      },
      specVerifying: {
        title: 'Waiting for specification confirmation',
        waitDescription: 'Wait for the manager to confirm the specification.',
        approveDescription: 'The manager marked the specification document as correct.',
      },
      transitCountry: {
        title: 'Wait for the manager to complete the deal',
        description: 'Wait for the manager to complete the deal',
      },
      transitCountryAwb: {
        title: 'Upload the AWB of the transit country or enter the flight number',
      },
      transitCountryAwbVerifying: {
        title: 'Waiting for AWB check',
        waitDescription: 'Wait for the manager to check the AWB',
        approveDescription: 'The manager marked the AWB document as correct.',
      },
      cargoShipped: 'The manager noted that the cargo was shipped',
    },
    customer: {
      shipmentType: {
        title: 'Wait for shipment',
      },
      destinationCountryDocs: {
        title: 'Wait for the deal documents to load',
        waitDescription: 'Once the documents are uploaded they will appear here',
        approveDescription: 'Documents uploaded',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Invoice',
          [DocumentFileType.customerSpecification]: 'Specification',
          [DocumentFileType.customerAcceptanceCertificate]: 'Transfer supplementary agreement signed on one side',
          [DocumentFileType.customerAdditionalAgreement]: 'Supplementary agreement',
        },
      },
      destinationCountryAwb: {
        title: 'Wait for AWB or flight number to load',
        waitDescription: 'When the AWB or flight number is loaded it will appear here',
        approveFileDescription: 'Manager uploaded AWB',
        approveFlightNunberDescription: 'The manager indicated the flight number',
      },
      transfer: {
        title: 'Cargo shipped',
      },
    },
  },
  ru: {
    manager: {
      uploadSpec: {
        title: 'Загрузите спецификацию поставщика',
        description: 'Загрузите спецификацию и нажмите “Подтвердить”',
        modal: {
          title: 'Подтвердите удаление файла',
          okText: 'Удалить',
        },
      },
      specVerifying: {
        title: 'Проверьте спецификацию',
        description:
          'Cкачайте документ спецификации из предыдущего шага. Проверьте, что вся информация верна и нажмите кнопку “Все правильно”, чтобы пройти дальше.',
      },
      shipmentType: {
        title: 'Выберите тип перелета',
        [ShipmentType.direct]: 'Прямой',
        [ShipmentType.transit]: 'Транзит',
      },
      transitCountry: {
        title: 'Выберите страну транзита',
        placeholder: 'Выберите страну',
      },
      transitCountryAwb: {
        title: 'Загрузите AWB страны транзита или введите номер рейса',
      },
      transitCountryAwbVerifying: {
        title: 'Проверьте AWB страны транзита',
        description:
          'Cкачайте документ AWB страны транзита из предыдущего шага. Проверьте, что вся информация верна и нажмите кнопку “Все правильно”, чтобы пройти дальше.',
      },
      destinationCountryDocs: {
        title: 'Загрузите документы для страны назначения',
      },
      destinationCountryAwb: {
        title: 'Загрузите AWB страны назначения или введите номер рейса',
        description: 'Загрузите AWB для страны назначения и нажмите “Подтвердить”',
      },
      cargoShipment: {
        title: 'Отгрузка груза',
        description: 'Когда груз будет отправлен, подтвердите отгрузку',
      },
    },
    supplier: {
      uploadSpec: {
        title: 'Загрузите спецификацию поставщика',
        description: 'Загрузите спецификацию и нажмите “Подтвердить”',
      },
      specVerifying: {
        title: 'Ожидание подтверждения спецификации',
        waitDescription: 'Дождитесь, пока менеджер подтвердит спецификацию.',
        approveDescription: 'Менеджер отметил документ спецификации правильным.',
      },
      transitCountry: {
        title: 'Ожидайте заполнение сделки менеджером',
        description: 'Дождитесь, пока менеджер дозаполнит сделку',
      },
      transitCountryAwb: {
        title: 'Загрузите AWB страны транзита или введите номер рейса',
      },
      transitCountryAwbVerifying: {
        title: 'Ожидание проверки AWB',
        waitDescription: 'Дождитесь, пока менеджер проверит AWB',
        approveDescription: 'Менеджер отметил документ AWB правильным.',
      },
      cargoShipped: 'Менеджер отметил, что груз отгружен',
    },
    customer: {
      shipmentType: {
        title: 'Ожидайте отгрузку',
      },
      destinationCountryDocs: {
        title: 'Ожидайте загрузку документов по сделке',
        waitDescription: 'Когда документы будут загружены, они появятся здесь',
        approveDescription: 'Документы загружены',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Инвойс',
          [DocumentFileType.customerSpecification]: 'Спецификация',
          [DocumentFileType.customerAcceptanceCertificate]: 'Акт-приема передачи, подписанный с одной стороны',
          [DocumentFileType.customerAdditionalAgreement]: 'Дополнительное соглашение',
        },
      },
      destinationCountryAwb: {
        title: 'Ожидайте загрузку AWB или номера рейса',
        waitDescription: 'Когда AWB или номер рейса будет загружен, он появится здесь',
        approveFileDescription: 'Менеджер загрузил AWB',
        approveFlightNunberDescription: 'Менеджер указал номер рейса',
      },
      transfer: {
        title: 'Груз отгружен',
      },
    },
  },
};
