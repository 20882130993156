import { Nullable } from '@/shared/types/values';

import { ISignatoryDictionary } from '../types/client';
import { SingatoryDictionaryServer } from '../types/server';

export class SignatoryModel implements ISignatoryDictionary {
  readonly value: number;
  readonly label: string;
  readonly name: string;
  readonly type: Nullable<string>;
  readonly signLink: Nullable<string>;

  constructor({ value, label, name, type, signLink }: ISignatoryDictionary) {
    this.value = value;
    this.label = label;
    this.name = name;
    this.type = type;
    this.signLink = signLink;
  }

  static fromJson(server: SingatoryDictionaryServer): SignatoryModel {
    return new SignatoryModel({
      value: server.id,
      label: server.name,
      name: server.name,
      type: server.type,
      signLink: server.sign && server.sign.link,
    });
  }
}
