import { BaseResponse } from '@kts-front/types';

import { ListModel, LoadingStageModel } from '@/shared/model';
import { ID } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { DictionaryModel } from '../model/DictionaryModel';

export interface IDictionary<T extends ID = number> {
  value: T;
  label: string;
  name: string;
}

export interface IUnitDictionary<T extends ID = number> extends IDictionary<T> {
  slug: string;
}

export interface IProductDictionary extends IDictionary {
  productList: ListModel<IDictionary, number>;
  unitList: ListModel<IDictionary, number>;
}

export interface ISignatoryDictionary extends IDictionary {
  type: Nullable<string>;
  signLink: Nullable<string>;
}

export enum DictionaryField {
  suppliers = 'suppliers',
  customers = 'customers',
  countries = 'countries',
  currencies = 'currencies',
  legalCompanies = 'legalCompanies',
  logisticCompanies = 'logisticCompanies',
  productTypes = 'productTypes',
  signatories = 'signatories',
}

export interface IDictionaryStore<Model extends DictionaryModel<ID>, K extends ID = number> {
  readonly list: ListModel<Model, K>;
  load(): Promise<BaseResponse>;
}

export interface IDictionariesStore {
  readonly legalCompanies: IDictionaryStore<IDictionary>;
  readonly logisticCompanies: IDictionaryStore<IDictionary>;
  readonly currencies: IDictionaryStore<IDictionary>;
  readonly productTypes: IDictionaryStore<IProductDictionary>;
  readonly countries: IDictionaryStore<IDictionary>;
  readonly suppliers: IDictionaryStore<IDictionary>;
  readonly customers: IDictionaryStore<IDictionary>;
  readonly signatories: IDictionaryStore<ISignatoryDictionary>;

  readonly loadingStage: LoadingStageModel;
  readonly loadingClientsStage: LoadingStageModel;

  loadClients(): Promise<BaseResponse>;
  loadDictionaries(fields: DictionaryField[]): Promise<BaseResponse>;
}
