import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, reaction } from 'mobx';

import { IChat, ThreadListPayload, ThreadListReaponse, ThreadModel, ThreadServer, ThreadType } from '@/entities/chat';
import { MessageType } from '@/entities/message';
import { TradeStageFilterModel } from '@/entities/trade';
import {
  CreatedMessageWsBody,
  CreatedThreadWsBody,
  DeletedMessageWsBody,
  EditedMessageWsBody,
  ReadMessagesWsBody,
} from '@/entities/ws';
import { apiStore, apiUrls } from '@/shared/api';
import { Nullable } from '@/shared/types/values';

import { BaseChatListModel, BaseChatListModelParams } from '../BaseChatListModel';

type ThreadListStoreParams = BaseChatListModelParams & {
  chat: IChat;
};

export class ThreadListModel extends BaseChatListModel<ThreadModel, ThreadListPayload> {
  readonly tradeStagesModel: TradeStageFilterModel;

  private readonly _threadListRequest: ApiRequest<ThreadListReaponse>;
  private _chat: IChat;

  constructor({ chat, ...params }: ThreadListStoreParams) {
    super({
      ...params,
      searchValue: params.queryParams.params.thread_list_search,
    });

    this.tradeStagesModel = new TradeStageFilterModel({
      initialValue: params.queryParams.params.trade_stages,
      rootStore: params.rootStore,
    });

    this._chat = chat;

    this._threadListRequest = apiStore.createRequest({
      url: apiUrls.chats.threadList(chat.id),
    });

    makeObservable(this, {
      proxyThread: computed,
      sortedList: computed,

      handleCreatedThreadWsEvent: action.bound,
      handleCreatedMessageWsEvent: action.bound,
      handleReadMessagesWsEvent: action.bound,
      handleEditedMessageWsEvent: action.bound,
      handleDeletedMessageWsEvent: action.bound,
    });

    this.addReactions([
      reaction(
        () => this.tradeStagesModel.toJson,
        () => this.applyParams(),
      ),
      reaction(
        () => {
          const queryParams = this._queryParams.params;

          return {
            search: queryParams.thread_list_search,
            tradeStages: queryParams.trade_stages,
          };
        },
        (current, prev) => {
          if (this._chat.chatsStore.chats.selectedItem?.id !== this._chat.id) {
            return;
          }

          if (
            current.search !== prev.search ||
            current.tradeStages.length !== prev.tradeStages.length ||
            current.tradeStages.some((value, index) => value !== prev.tradeStages[index])
          ) {
            this.initialLoad({ reset: true });
          }
        },
      ),
    ]);
  }

  get sortedList(): ThreadModel[] {
    const copy = [...this.list.items];

    return copy.sort((item1, item2) => {
      if (item1.pinned) {
        return -1;
      }

      if (item2.pinned) {
        return 1;
      }

      return 0;
    });
  }

  get proxyThread(): Nullable<ThreadModel> {
    return this.list.items.find((item) => item.type === ThreadType.proxy) ?? null;
  }

  applyParams(): void {
    this._queryParams.setParams({
      thread_list_search: this._searchModel.value,
      trade_stages: this.tradeStagesModel.toJson,
    });
  }

  resetParams(): void {
    this._selectedItemModel.change(null);
    this._searchModel.change('');
    this.tradeStagesModel.change([]);
    this.applyParams();
  }

  async handleCreatedThreadWsEvent(body: CreatedThreadWsBody): Promise<void> {
    await this._moveItemToTop(body.thread_id);
  }

  async handleCreatedMessageWsEvent(body: CreatedMessageWsBody): Promise<void> {
    const threadId = body.target_thread_id;

    if (this.selectedItem && this.selectedItem.id === threadId) {
      this.selectedItem.handleCreatedMessageWsEvent(body);
    }

    /*
     * Если ни разу не загружали список, то ничего не делаем
     * */
    if (!this.initiallyLoaded) {
      return;
    }

    const result = await this._moveItemToTop(threadId);

    /*
     * В случае, если такой thread был в списке и он не равен выбранному, обрабатываем событие
     * */
    if (!result.isError && !result.data.isNewInList && this.selectedItem !== result.data.item) {
      const thread = result.data.item;

      thread.handleCreatedMessageWsEvent(body);
    }
  }

  handleReadMessagesWsEvent(body: ReadMessagesWsBody): void {
    const threadId = body.thread_id;
    const thread = this.list.getEntity(threadId);

    if (thread) {
      thread.handleReadMessagesWsEvent(body);
    }

    const selectedTread = this.selectedItem;

    /*
     * В случае, если есть выбранный thread и он не равен thread-у из списка, то обрабатываем событие
     * */
    if (selectedTread && selectedTread.id === threadId && selectedTread !== thread) {
      selectedTread.handleReadMessagesWsEvent(body);
    }
  }

  handleEditedMessageWsEvent(body: EditedMessageWsBody): void {
    const threadId = body.target_thread_id;
    const thread = this.list.getEntity(threadId);

    if (thread) {
      thread.handleEditedMessageWsEvent(body);
    }

    const selectedTread = this.selectedItem;

    /*
     * В случае, если есть выбранный thread и он не равен thread-у из списка, то обрабатываем событие
     * */
    if (selectedTread && selectedTread.id === threadId && selectedTread !== thread) {
      selectedTread.handleEditedMessageWsEvent(body);
    }
  }

  handleDeletedMessageWsEvent(body: DeletedMessageWsBody): void {
    const result = this._moveItemToPosition(
      ThreadModel.fromJson({
        chat: this._chat,
        server: { ...body.target_thread, chat: body.chat },
        rootStore: this._rootStore,
        queryParams: this._queryParams,
      }),
    );

    if (!result.isError) {
      const thread = result.data;
      thread.handleDeletedMessageWsEvent(body);
    }

    const selectedTread = this.selectedItem;

    /*
     * В случае, если есть выбранный thread и он не равен thread-у из списка, то обрабатываем событие
     * */
    if (
      selectedTread &&
      selectedTread.id === body.target_thread.id &&
      (result.isError || (!result.isError && result.data !== selectedTread))
    ) {
      selectedTread.handleDeletedMessageWsEvent(body);
    }
  }

  protected get _paramsJson(): Nullable<ThreadListPayload> {
    const search = this._searchModel.value;
    const tradeStages = this.tradeStagesModel.toJson;

    if (!search && !tradeStages.length) {
      return null;
    }

    return {
      ...(search ? { search } : {}),
      ...(tradeStages.length ? { key: tradeStages } : {}),
    };
  }

  protected async _fetchItems(reset: boolean): Promise<BaseResponse> {
    if (reset) {
      this.list.changeInitial(true);
    }

    const params = {
      limit: this.list.limit,
      offset: this.list.offset,
      ...(this._paramsJson ?? {}),
    };

    const response = await this._threadListRequest.call({
      params,
    });

    if (response.isError) {
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.loadChatListError', { ns: 'chats' }),
      });

      return { isError: true };
    }

    this.list.fillByRawData(
      response.data.results,
      (server) => ({
        key: server.id,
        entity: ThreadModel.fromJson({
          server,
          rootStore: this._rootStore,
          queryParams: this._queryParams,
          chat: this._chat,
        }),
      }),
      reset,
    );
    this.list.changeInitial(false);
    this.list.total.change(response.data.total);

    return { isError: false };
  }

  protected async _fetchItem(threadId: number, resetParams: boolean = false): Promise<BaseResponse<ThreadModel>> {
    if (resetParams) {
      this.resetParams();
    }

    const response = await apiStore
      .createRequest<Nullable<ThreadServer>>({
        params: this._paramsJson ?? {},
        url: apiUrls.chats.thread(threadId),
      })
      .call();

    if (response.isError || !response.data) {
      return { isError: true };
    }

    return {
      isError: false,
      data: ThreadModel.fromJson({
        server: response.data,
        queryParams: this._queryParams,
        rootStore: this._rootStore,
        chat: this._chat,
      }),
    };
  }
}
