export const createPassword = {
  en: {
    title: 'You have been invited to the Tranomica CRM system, welcome!',
    form: {
      email: 'Email address',
      password: 'Create password',
      repeatPassword: 'Repeat password',
      placeholder: 'Password',
      submit: 'Continue',
    },
    errors: {
      mismatch: 'Passwords do not match',
    },
    messages: {
      success: 'Password successfully changed',
      error: 'An error occurred while creating a password',
    },
  },
  ru: {
    title: 'Вас пригласили в СRM систему Tranomica, добро пожаловать!',
    form: {
      email: 'Адрес почты',
      password: 'Придумайте пароль',
      repeatPassword: 'Повторите пароль',
      placeholder: 'Пароль',
      submit: 'Продолжить',
    },
    errors: {
      mismatch: 'Пароли не совпадают',
    },
    messages: {
      success: 'Пароль успешно изменен',
      error: 'Произошла ошибка при создании пароля',
    },
  },
};
